export default {
    api: 'https://backend-spider-kimberly-corporativa.grow-corporate.com/',
    // api: 'http://127.0.0.1:8000/',
    apitwo: 'https://backend-smartview.grow-corporate.com/',
    // apiNode: 'http://127.0.0.1:3001/',
    apiNode: 'https://express-backend-spider.grow-corporate.com/',
    basename: '/',
    nombreSistema : 'Spider',
    nombreSistemaHo : 'Spider',
    nombreCliente : 'los países Kimberly Clark Perú, Bolivia y Chile',
};
