export const OBTENER_LISTA_DATA_DASHBOARD = "OBTENER_LISTA_DATA_DASHBOARD"
export const OBTENER_LISTA_TOTAL_DATA_DASHBOARD = "OBTENER_LISTA_TOTAL_DATA_DASHBOARD"
export const OBTENER_LISTA_DATA_COPIA_DASHBOARD = "OBTENER_LISTA_DATA_COPIA_DASHBOARD"
export const CARGANDO_LISTA_DATA = "CARGANDO_LISTA_DATA"
export const CARGANDO_LISTA_DATA_DERECHA = "CARGANDO_LISTA_DATA_DERECHA"
export const TABLA_EXPANDED_DATA = "TABLA_EXPANDED_DATA"
export const TOTAL_EXPANDED_DATA = "TOTAL_EXPANDED_DATA"
export const DEFAULT_EXPANDED_DATA = "DEFAULT_EXPANDED_DATA"
export const TOTAL_QUERY_DATA = "TOTAL_QUERY_DATA"
export const OBTENER_DATA_QUERY_1 = "OBTENER_DATA_QUERY_1"
export const OBTENER_DATA_QUERY_2 = "OBTENER_DATA_QUERY_2"
export const OBTENER_FECHA_QUERY = "OBTENER_FECHA_QUERY"
export const MES_VALIDATOR = "MES_VALIDATOR"
export const OBTENIENDO_FECHAS_ALL = "OBTENIENDO_FECHAS_ALL"
export const OBTENER_CODIGO_FECHA = "OBTENER_CODIGO_FECHA"