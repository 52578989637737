import {
    OBTENER_PERMISOS_ESPECIALES_USUARIO_ADMINISTRADOR_USUARIOS
} from '../../../../constants/Administrador/Usuarios/Usuarios'

const INIT_STATE = {

    rex_permisos_especiales_usuario : []
    
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case OBTENER_PERMISOS_ESPECIALES_USUARIO_ADMINISTRADOR_USUARIOS: {
            return {
                ...state,
                rex_permisos_especiales_usuario : action.payload
            }
        }
        default:
            return state;
    }
}