import {
    CARGANDO_PERMISOS_TIPO_USUARIO_CONTROL_ACCESO,
    OBTENER_PERMISOS_TIPO_USUARIO_CONTROL_ACCESO,
    PODER_GUARDAR_PERMISOS_TIPO_USUARIO_CONTROL_ACCESO,
    FILTROS_PERMISOS_PAISES,
    FILTROS_PERMISO_PAIS_SELECCIONADO,
    FILTROS_PERMISOS_PAISES_SELECT
} from "../../../../constants/SistemaTypes";

const INIT_STATE = {
    cargandoPermisosTipoUsuario : false,
    permisosTipoUsuario         : [],
    guardarPermisos             : false,
    tpuidSeleccionado           : 0,
    filtrosPermisosPaises       : [],
    filtroPaisSeleccionado      : null,
    filtrosPermisosPaisesSelect : []
};


export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FILTROS_PERMISOS_PAISES_SELECT: {
        return {
            ...state,
            filtrosPermisosPaisesSelect: action.payload
        }
    }
    case FILTROS_PERMISO_PAIS_SELECCIONADO: {
        return {
            ...state,
            filtroPaisSeleccionado: action.payload
        }
    }
    case FILTROS_PERMISOS_PAISES: {
        return {
            ...state,
            filtrosPermisosPaises: action.payload
        }
    }
    case CARGANDO_PERMISOS_TIPO_USUARIO_CONTROL_ACCESO: {
        return {
            ...state,
            cargandoPermisosTipoUsuario: action.payload
        }
    }
    case OBTENER_PERMISOS_TIPO_USUARIO_CONTROL_ACCESO: {
        return {
            ...state,
            permisosTipoUsuario: action.payload.permisos,
            tpuidSeleccionado: action.payload.tpuid
        }
    }
    case PODER_GUARDAR_PERMISOS_TIPO_USUARIO_CONTROL_ACCESO: {
        return {
            ...state,
            guardarPermisos: action.payload
        }
    }
    default:
      return state;
  }
}
