import {
    OBTENER_DATA_DAILY_GRAFICO,
    OBTENER_DATA_DAILY_GRAFICO_COPY,
    SELECCIONAR_FILTRO_DAILY_ANIO,
    SELECCIONAR_FILTRO_DAILY_MES,
    CARGANDO_DATA_DAILY,
    SELECCIONAR_FILTRO_DAILY_COD_MAT,
    SELECCIONAR_FILTRO_DAILY_HML_MAT,
    SELECCIONAR_FILTRO_DAILY_HML_CLI,
    SELECCIONAR_FILTRO_DAILY_TALLA,
    SELECCIONAR_FILTRO_DAILY_CONTEO,
    SELECCIONAR_FILTRO_DAILY_FORMATO
} from '../../../constants/SimplePowerBi/Daily'


const INIT_STATE = {
    rex_select_daily_anio           : null,
    rex_select_daily_mes            : null,
    rex_select_daily__hml_cod_mat   : false,
    rex_select_daily_hml_mat        : false,
    rex_select_daily_conteo         : false,
    rex_select_daily_talla          : false,
    rex_select_daily_formato          : false,
    rex_select_daily_hml_cli        : false,
    rex_cargando_data_daily         : false,
    rex_data_daily_grafico_copy     : [],
    rex_data_daily_grafico          : []
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case OBTENER_DATA_DAILY_GRAFICO :
            return {
                ...state,
                rex_data_daily_grafico : action.payload
            }
        case OBTENER_DATA_DAILY_GRAFICO_COPY :
            return {
                ...state,
                rex_data_daily_grafico_copy : action.payload
            }
        case SELECCIONAR_FILTRO_DAILY_ANIO :
            return {
                ...state,
                rex_select_daily_anio : action.payload
            }
        case SELECCIONAR_FILTRO_DAILY_MES :
            return {
                ...state,
                rex_select_daily_mes : action.payload
            }
        case CARGANDO_DATA_DAILY :
            return {
                ...state,
                rex_cargando_data_daily : action.payload
            }
        case SELECCIONAR_FILTRO_DAILY_COD_MAT :
            return {
                ...state,
                rex_select_daily__hml_cod_mat : action.payload
            }
        case SELECCIONAR_FILTRO_DAILY_HML_MAT :
            return {
                ...state,
                rex_select_daily_hml_mat : action.payload
            }
        case SELECCIONAR_FILTRO_DAILY_HML_CLI :
            return {
                ...state,
                rex_select_daily_hml_cli : action.payload
            }
        case SELECCIONAR_FILTRO_DAILY_TALLA :
            return {
                ...state,
                rex_select_daily_talla : action.payload
            }
        case SELECCIONAR_FILTRO_DAILY_CONTEO:
            return {
                ...state,
                rex_select_daily_conteo : action.payload
            }
        case SELECCIONAR_FILTRO_DAILY_FORMATO:
            return {
                ...state,
                rex_select_daily_formato : action.payload
            }
        default:
            return state;
    }
}