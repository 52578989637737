import {
    CONTROLES_ACCESOS_USUARIOS_CARGANDO_TABLA,
    CONTROLES_ACCESOS_USUARIOS_OBTENER_USUARIOS,
    CONTROLES_ACCESOS_USUARIOS_OBTENER_COLUMNAS_TABLA_USUARIOS,
    CONTROLES_ACCESOS_USUARIOS_VISIBILIDAD_MODAL_NUEVO_USUARIO,
    CONTROLES_ACCESOS_USUARIOS_CARGANDO_NUEVO_USUARIO,
    CONTROLES_ACCESOS_USUARIOS_EDITAR_USUARIOS,
    CONTROLES_ACCESOS_USUARIOS_OBTENER_UNICAMENTE_USUARIOS,
    CONTROLES_ACCESOS_USUARIOS_FILTRAR_USUARIOS,
    CONTROLES_ACCESOS_USUARIOS_USUARIOS_EDITANDO,
    CONTROLES_ACCESOS_USUARIOS_USUARIOS_ACTUALIZADOS
} from "../../../../constants/SistemaTypes";

const INIT_STATE = {
    cargandoTablaUsuarios   : false,
    cargandoNuevoUsuario    : false,
    columnasTablaUsuarios   : [],
    listaUsuarios           : [],
    listaUsuariosFiltro     : [],
    usuarioEditar           : [],
    usuariosEditando_rx     : [],
    usuariosActualizados_rx : [],
    filtroUsuarios        :false,
    visibleModalNuevoUsuario : false
};


export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CONTROLES_ACCESOS_USUARIOS_CARGANDO_TABLA: {
        return {
            ...state,
            cargandoTablaUsuarios: action.payload
        }
    }
    
    case CONTROLES_ACCESOS_USUARIOS_OBTENER_USUARIOS: {
        return {
            ...state,
            listaUsuarios: action.payload,
            cargandoTablaUsuarios: false
        }
    }

    case CONTROLES_ACCESOS_USUARIOS_OBTENER_UNICAMENTE_USUARIOS: {
        return {
            ...state,
            listaUsuarios: action.payload,
        }
    }
    case CONTROLES_ACCESOS_USUARIOS_EDITAR_USUARIOS: {

        return {
            ...state,
            usuarioEditar: action.payload,
        }
    }

    case CONTROLES_ACCESOS_USUARIOS_OBTENER_COLUMNAS_TABLA_USUARIOS: {
        return {
            ...state,
            columnasTablaUsuarios : action.payload
        }
    }
    case CONTROLES_ACCESOS_USUARIOS_VISIBILIDAD_MODAL_NUEVO_USUARIO: {
        return {
            ...state,
            visibleModalNuevoUsuario : action.payload
        }
    }
    case CONTROLES_ACCESOS_USUARIOS_CARGANDO_NUEVO_USUARIO: {
        return {
            ...state,
            cargandoNuevoUsuario : action.payload
        }
    }

    case CONTROLES_ACCESOS_USUARIOS_FILTRAR_USUARIOS: {

        return {
            ...state,
            listaUsuariosFiltro : action.payload.lista
        }
    }
    case CONTROLES_ACCESOS_USUARIOS_USUARIOS_EDITANDO: {

        return {
            ...state,
            usuariosEditando_rx : action.payload
        }
    }
    
    case CONTROLES_ACCESOS_USUARIOS_USUARIOS_ACTUALIZADOS: {

        return {
            ...state,
            usuariosActualizados_rx : action.payload
        }
    }

    default:
      return state;
  }
}
