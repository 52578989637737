
export const OBTENER_DATA_DAILY_GRAFICO = "OBTENER_DATA_DAILY_GRAFICO"
export const OBTENER_DATA_DAILY_GRAFICO_COPY = "OBTENER_DATA_DAILY_GRAFICO_COPY"
export const SELECCIONAR_FILTRO_DAILY_ANIO = "SELECCIONAR_FILTRO_DAILY_ANIO"
export const SELECCIONAR_FILTRO_DAILY_MES = "SELECCIONAR_FILTRO_DAILY_MES"
export const CARGANDO_DATA_DAILY = "CARGANDO_DATA_DAILY"
export const SELECCIONAR_FILTRO_DAILY_COD_MAT = "SELECCIONAR_FILTRO_DAILY_COD_MAT"
export const SELECCIONAR_FILTRO_DAILY_HML_MAT = "SELECCIONAR_FILTRO_DAILY_HML_MAT"
export const SELECCIONAR_FILTRO_DAILY_HML_CLI = "SELECCIONAR_FILTRO_DAILY_HML_CLI"
export const SELECCIONAR_FILTRO_DAILY_CONTEO = "SELECCIONAR_FILTRO_DAILY_CONTEO"
export const SELECCIONAR_FILTRO_DAILY_TALLA = "SELECCIONAR_FILTRO_DAILY_TALLA"
export const SELECCIONAR_FILTRO_DAILY_FORMATO = "SELECCIONAR_FILTRO_DAILY_FORMATO"