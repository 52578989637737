let date = new Date()

const INIT_STATE = {
    rex_mes_seleccionado_descarga : "Oct",
    rex_anio_seleccionado_descarga : date.getFullYear(),
    rex_nombre_archivo_seleccionado_descarga : "",
    rex_arr_filtros_descarga : [],
    rex_eszona_descargar : false
};


export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "CAMBIAR_MES_SELECCIONADO_DESCARGA": {
        return {
            ...state,
            rex_mes_seleccionado_descarga : action.payload,
        }
    }
    case "CAMBIAR_ANIO_SELECCIONADO_DESCARGA": {
        return {
            ...state,
            rex_anio_seleccionado_descarga : action.payload,
        }
    }
    case "CAMBIAR_NOMBRE_ARCHIVO_SELECCIONADO_DESCARGA": {
      return {
          ...state,
          rex_nombre_archivo_seleccionado_descarga : action.payload,
      }
    }
    case "OBTENER_ARR_FILTROS_DESCARGA": {
      return {
          ...state,
          rex_arr_filtros_descarga : action.payload,
      }
    }
    case "SELECCIONAR_DESCARGA_POR_ZONA_DESCARGA": {
      return {
          ...state,
          rex_eszona_descargar : action.payload,
      }
    }
    default:
      return state;
  }
}
