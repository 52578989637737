export const BOTONES_FILTRO = "BOTONES_FILTRO"
export const OBTENER_DATA_FORMATO = "OBTENER_DATA_FORMATO"
export const OBTENER_DATA_TALLA = "OBTENER_DATA_TALLA"
export const OBTENER_DATA_CONTEO = "OBTENER_DATA_CONTEO"
export const OBTENER_DATA_SKU = "OBTENER_DATA_SKU"
export const OBTENER_DATA_DESCRIPCION_MATERIAL = "OBTENER_DATA_DESCRIPCION_MATERIAL"
export const OBTENER_DATA_CLIENTE_HML = "OBTENER_DATA_CLIENTE_HML"
export const OBTENER_DATA_ANIOS = "OBTENER_DATA_ANIOS"
export const OBTENER_DATA_MESES = "OBTENER_DATA_MESES"

export const OBTENER_TYPE_DATA_DAILY = "OBTENER_TYPE_DATA_DAILY"
export const OBTENER_DATA_CANAL = "OBTENER_DATA_CANAL"
export const OBTENER_DATA_EJECUTIVO = "OBTENER_DATA_EJECUTIVO"
export const OBTENER_DATA_CATEGORIA = "OBTENER_DATA_CATEGORIA"
export const OBTENER_DATA_SUBCATEGORIA = "OBTENER_DATA_SUBCATEGORIA"
export const SELECCIONADO_DATA_FORMATO = "SELECCIONADO_DATA_FORMATO"
export const SELECCIONADO_DATA_TALLA = "SELECCIONADO_DATA_TALLA"
export const SELECCIONADO_DATA_CONTEO = "SELECCIONADO_DATA_CONTEO"
export const SELECCIONADO_DATA_SKU = "SELECCIONADO_DATA_SKU"
export const SELECCIONADO_DATA_DESCRIPCION_MATERIAL = "SELECCIONADO_DATA_DESCRIPCION_MATERIAL"
export const SELECCIONADO_DATA_CLIENTE_HML = "SELECCIONADO_DATA_CLIENTE_HML"